/*
  // @route    POST /api/ordenes
  // @desc     POST an order
  // @access   Public
*/
export async function createOrder(formData) {
  const data = JSON.stringify(formData);
  try {
    // const addressShipping = address;
    const url = `${process.env.REACT_APP_API_URL}/api/ordenes`;
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    const result = await fetch(url, params);
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
