import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

// Custom components
import ProductCard from "../components/ProductCard/ProductCard";

// API
import { getSubCategoryProducts } from "../api/product";

// Styles
import "../scss/pages/category.styles.scss";

export default function Subcategory() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  let { category, slug } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getSubCategoryProducts(category, slug);
      if (response?.data.length > 0) {
        for (let index = 0; index < response.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.updatedAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.categoria.data.attributes
            .createdAt;
          delete response.data[index].attributes.categoria.data.attributes
            .updatedAt;
          delete response.data[index].attributes.categoria.data.attributes
            .publishedAt;
          if (response.data[index].attributes.imagenes.data.length > 0) {
            for (
              let i = 0;
              i < response.data[index].attributes.imagenes.data.length;
              i++
            ) {
              delete response.data[index].attributes.imagenes.data[i].attributes
                .alternativeText;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .caption;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .createdAt;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .ext;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .formats;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .hash;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .height;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .mime;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .name;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .previewUrl;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .size;
              delete response.data[index].attributes.imagenes.data[i].attributes
                .updatedAt;
            }
          }
        }
        setProducts(response.data);
        setLoading(false);
      } else {
        setProducts([]);
        setLoading(false);
      }
    })();
  }, [category, slug]);

  // console.log(products);

  function makeTitle(slug) {
    var words = slug.split("-");

    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0) + word.slice(1);
    }

    setCategoryName(words.join(" "));
  }

  useEffect(() => {
    makeTitle(slug);
  }, [slug]);
  return (
    <>
      <Container className="subcategory__container">
        <h4>{categoryName}</h4>
        <div className="products__container">
          <Row>
            {loading && <p className="text-center">Cargando...</p>}
            {!loading && products.length === 0 && (
              <p>Por el momento no hay productos.</p>
            )}
            {products?.map((product, key) => (
              <ProductCard
                key={key}
                product={product}
                id={product.id}
                media={product.media}
                name={product.name}
                category={product.category}
                sizes={product.sizes}
                price={product.price}
              />
            ))}
          </Row>
        </div>
      </Container>
    </>
  );
}
