import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

// API
import { getProduct } from "../api/product";

// Context
import { useCartData } from "../contexts/CartContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// Custom components
import { ProductCarousel } from "../components/ProductCarousel/ProductCarousel";

// Styles
import "../scss/pages/product.styles.scss";

// Assets
import product2 from "../assets/product-2.jpg";
import checkoutIcon from "../assets/checkout-icon.svg";

// Utils
import formatMoney from "../utils/formatMoney";

export default function Product() {
  const [quantity, setQuantity] = useState(1);
  const [productSize, setProductSize] = useState("");
  const [productSizeId, setProductSizeId] = useState(0);
  const [stock, setStock] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [product, setProduct] = useState(null);
  // let loading = false;
  let { id } = useParams();
  const { addProduct, cartItems, increase } = useCartData();
  const history = useHistory();

  function decrease() {
    if (quantity === 1) {
      return;
    }
    setQuantity(quantity - 1);
  }

  const isInCart = (product, productSize) => {
    // setLoading(true);
    return !!cartItems.find(
      (item) => item.id === product?.id && item.productSize === productSize
    );
  };

  const handleClick = () => {
    // setLoading(true);
    isInCart(product, productSize, productSizeId)
      ? increase(product, quantity, productSize, productSizeId)
      : addProduct(product, quantity, productSize, productSizeId);
    // return setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getProduct(id);
      if (response?.data) {
        delete response?.data.attributes.createdAt;
        delete response?.data.attributes.updatedAt;
        delete response?.data.attributes.publishedAt;
        delete response?.data.attributes.categoria.data.attributes.createdAt;
        delete response?.data.attributes.categoria.data.attributes.updatedAt;
        delete response?.data.attributes.categoria.data.attributes.publishedAt;
        setProduct(response.data);
        setLoading(false);
      } else {
        setProduct([]);
        setLoading(false);
      }
    })();
  }, [id]);
  // console.log(product);
  if (loading) {
    return (
      <main>
        <Container className="product__container">
          <p>Cargando...</p>
        </Container>
      </main>
    );
  }

  return (
    <main>
      <Container className="product__container">
        <Row className="product">
          {/* <Link to="/productos">Regresar</Link> */}
          <Col lg="5" className="product__media">
            {product?.attributes.imagenes.data.length > 0 ? (
              <ProductCarousel
                productName={product?.attributes.nombre}
                productImages={product?.attributes.imagenes.data}
              />
            ) : (
              <img
                src={`${product?.attributes?.imagenes.data[0].attributes.url}`}
                // src={`${process.env.REACT_APP_API_URL}${product?.attributes?.imagenes.data[0].attributes.url}`}
                className="img-fluid"
                alt="Norteño Boots"
              />
            )}
            {/* <img
              src={`${product?.attributes?.imagenes.data[0].attributes.url}`}
              // src={`${process.env.REACT_APP_API_URL}${product?.attributes?.imagenes.data[0].attributes.url}`}
              className="img-fluid"
              alt="Norteño Boots"
            /> */}
          </Col>
          <Col lg="7" className="product__specs">
            {product?.attributes.nuevo ? (
              <span className="product__specs__new">Nuevo</span>
            ) : (
              <></>
            )}
            <h2>{product?.attributes?.nombre}</h2>
            <span className="product__specs__category">
              {product?.attributes?.categoria.data.attributes.nombre}
            </span>
            <p className="product__specs__price">
              {formatMoney(product?.attributes?.precio)}
            </p>
            <p className="product__specs__description">
              {product?.attributes?.descripcion}
            </p>
            <div className="product__specs__sizes">
              <p>talla(s) disponibles:</p>
              <div className=" product__specs__sizes__container">
                {product?.attributes?.tallas.map((size, key) =>
                  size.stock > 0 ? (
                    <div className="form-group" key={key}>
                      <input
                        type="checkbox"
                        checked={checked}
                        // id={`${size.talla}`}
                        id={size.talla}
                        value={size.talla}
                        sizeid={size.id}
                        // onClick={(e) => setSize(e.target.value)}
                        // onBlur={(e) => setSize(0)}
                        disabled={checked && productSize !== size.talla}
                        onChange={(e) => {
                          setProductSizeId(
                            parseInt(e.target.getAttribute("sizeid"))
                          );
                          setProductSize(e.target.value);
                          setChecked(!checked);
                          setStock(size.stock);
                          if (checked) {
                            setProductSize("");
                            setStock(0);
                            setQuantity(1);
                          }
                        }}
                      />
                      <label htmlFor={`${size.talla}`}>{size.talla}</label>
                    </div>
                  ) : (
                    <div className="form-group text-center" key={key}>
                      <input type="checkbox" disabled={true} />
                      <label htmlFor={`${size.talla}`}>{size.talla}</label>
                      <p>Sin stock</p>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="product__specs__actions">
              <div className="product__specs__actions__quantity">
                <p>Cantidad</p>
                <div>
                  <button
                    type="button"
                    onClick={decrease}
                    disabled={quantity === 1}
                  >
                    -
                  </button>
                  <h5>{quantity}</h5>
                  <button
                    type="button"
                    onClick={() => {
                      if (quantity < stock) {
                        setQuantity(quantity + 1);
                      }
                    }}
                    disabled={productSize === "" || stock === quantity}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="product__specs__actions__add">
                <button
                  type="button"
                  disabled={loading || productSize === ""}
                  onClick={() => {
                    // }} //   setLoading(false); //     : addProduct(product, quantity); //     ? increase(product) //   isInCart(product) //   setLoading(true); // ={() => {
                    // isInCart(product, quantity, productSize);
                    handleClick();
                    // ? increase(product, quantity)
                    // : addProduct(product, quantity, productSize);
                    setLoading(true);
                    setProductSize("");
                    setStock(0);
                    setQuantity(1);
                    setChecked(false);
                    setTimeout(() => {
                      setLoading(false);
                    }, 450);
                    window.scrollTo(0, 0);
                  }}
                >
                  {loading && "Añadiendo a la bolsa"}
                  {!loading && productSize === "" ? "Selecciona una talla" : ""}
                  {!loading && productSize !== "" ? "Añadir al carrito" : ""}
                </button>
              </div>
              <div className="product__specs__actions__checkout">
                {productSize !== "" && (
                  <button
                    type="button"
                    title="Añadir a la bolsa y comprar"
                    onClick={() => {
                      handleClick();
                      history.push("/bolsa");
                    }}
                  >
                    <img src={checkoutIcon} alt="Norteño Products" />
                    <span>Añadir a la bolsa y comprar</span>
                  </button>
                )}
              </div>
            </div>
            <div className="product__specs__details">
              <p>
                skuid
                <span> {product?.attributes?.skuId}</span>
              </p>
              <p>
                categoria
                <span>
                  {" "}
                  {product?.attributes?.categoria.data.attributes.nombre}
                </span>
              </p>
              <p>
                marca
                <span>{product?.attributes?.marca}</span>
              </p>
            </div>
            <div className="product__specs__share">
              <p>compartir</p>
            </div>
          </Col>
        </Row>
        <Row className="product__info">
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Descripción">
              <p>{product?.attributes?.descripcion2}</p>
            </Tab>
            <Tab eventKey="profile" title="Información">
              {/* <p className="b-0">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam sed ut
                perspiciatis unde omnis iste.
              </p> */}
              <Row className="product__info__details">
                <Col lg="6">
                  <p>
                    <span>Marca: </span>
                    {product?.attributes?.marca}
                  </p>
                </Col>
                <Col lg="6">
                  <p>
                    <span>Color: </span>
                    {product?.attributes?.color}
                  </p>
                </Col>
                <Col lg="6">
                  <p>
                    <span>Material: </span>
                    {product?.attributes?.material}
                  </p>
                </Col>
                {/* <Col lg="6">
                  <p>
                    <span>Corte:</span>
                    Regular Fit
                  </p>
                </Col> */}
              </Row>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </main>
  );
}
