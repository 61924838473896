/*
  // @route    GET /api/subcategorias
  // @desc     Get all subcategorias
  // @access   Public
*/
export async function getSubcategories() {
  try {
    const sortItem = "sort=nombre:asc";
    const url = `${process.env.REACT_APP_API_URL}/api/subcategorias?${sortItem}&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}
