import React, { useState, useEffect } from "react";

// Context
import { useCartData } from "../contexts/CartContext";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom Components
import PaymentForm from "../components/PaymentForm/PaymentForm";

// Custom styles
import "../scss/pages/checkout.styles.scss";

// Assets
// import product1 from "../assets/product-2.jpg";

// Utils
import formatMoney from "../utils/formatMoney";

const stripeLib = loadStripe(
  "pk_live_51Kffv2D2xPBXAvvT7vy7fUq9DpVr83WmzUiEQ1H30DbOnKKgcFlRvC30xjiUpsL42xw9kNFAAiZiB1oGo38DuEKM00VTtMYqNh",
  {
    locale: "es",
  }
);

function ListItem({ cartItem, removeProduct }) {
  return (
    <div className="checkout__container__summary__list__item">
      <img
        src={`${cartItem.attributes.imagenes?.data[0].attributes.url}`}
        // src={`${process.env.REACT_APP_API_URL}${cartItem.attributes.imagenes?.data[0].attributes.url}`}
        alt={cartItem.name}
      />
      <div>
        <h6>{cartItem.attributes.nombre}</h6>
        <span style={{ color: "#575757" }}>Talla: {cartItem.productSize}</span>
        <p>{formatMoney(cartItem.attributes.precio)}</p>
        <span style={{ color: "#575757" }}>Cantidad: {cartItem.quantity}</span>
        <button type="button" onClick={() => removeProduct(cartItem)}>
          Eliminar
        </button>
      </div>
    </div>
  );
}

export default function Checkout() {
  const { cartItems, total, removeProduct } = useCartData();
  const [shipping, setShipping] = useState(25);
  const [orderTotal, setOrderTotal] = useState(0);

  useEffect(() => {
    (() => {
      // setLoading(true);
      setOrderTotal(parseFloat(total) + shipping);
    })();
  }, [total, shipping]);

  return (
    <main>
      <div className="checkout__container">
        <Container fluid>
          <Row>
            <Col xs="12" lg="8" xl="9" className="checkout__container__form">
              {cartItems.length > 0 && (
                <>
                  <h3>Pago</h3>
                  <p>
                    A continuación llena la información requerida para completar
                    tu orden.{" "}
                  </p>
                  <span>* Todos los campos son requeridos</span>
                  <Elements stripe={stripeLib}>
                    <PaymentForm />
                  </Elements>
                </>
              )}
            </Col>
            <Col xs="12" lg="4" xl="3" className="checkout__container__summary">
              <div className="checkout__container__summary__listing">
                <div className="checkout__container__summary__heading">
                  <h3>Resumen</h3>
                  <p>{cartItems?.length}</p>
                </div>
                <div className="checkout__container__summary__list">
                  {cartItems.length > 0 ? (
                    cartItems.map((cartItem, key) => (
                      <ListItem
                        key={key}
                        cartItem={cartItem}
                        removeProduct={removeProduct}
                      />
                    ))
                  ) : (
                    <p>No hay elementos en tu bolsa</p>
                  )}
                </div>
                {cartItems.length > 0 && (
                  <div className="checkout__container__summary__total">
                    <div>
                      <div className="d-flex justify-content-between">
                        <p>Subtotal</p>
                        <p>{formatMoney(total)}</p>
                      </div>
                      <div className="d-flex justify-content-between mt-3">
                        <p>Envío</p>
                        <p>{formatMoney(shipping)}</p>
                      </div>
                    </div>
                    <div className="py-3">
                      <h6>Total</h6>
                      <p className="total">{formatMoney(orderTotal)}</p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
}
