import React from "react";

// Styles
import "../scss/pages/contact.styles.scss";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

// Assets
import phoneIcon from "../assets/phone-icon--white.svg";
import waIcon from "../assets/wa-icon--white.svg";
import mailIcon from "../assets/mail-icon--white.svg";

export default function Contact() {
  return (
    <main>
      <section className="contact">
        <Container>
          <h2>¿Cómo podemos ayudarte?</h2>
          <p className="contact-paragraph">
            Ponemos a tu disposición los siguientes medios de comunicación para
            resolver cualquier duda o comunicarnos algún problema con tu compra.
          </p>
          <Row xs={1} md={2} lg={3}>
            <Col>
              <Card>
                <Card.Img variant="top" src={phoneIcon} />
                <Card.Body>
                  <Card.Text>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Obcaecati, labore.
                  </Card.Text>
                  <a href="tel:4491234567">(449) 123 45 67</a>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={waIcon} />
                <Card.Body>
                  <Card.Text>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Obcaecati, labore.
                  </Card.Text>
                  <a href="tel:4491234567">(449) 123 45 67</a>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Img variant="top" src={mailIcon} />
                <Card.Body>
                  <Card.Text>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Obcaecati, labore.
                  </Card.Text>
                  <a href="mailto:soporte@nortenoboots.com.mx" className="mail">
                    soporte@nortenoboots.com.mx
                  </a>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}
