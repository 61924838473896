import React from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Styles
import "./footer.styles.scss";

// Assets
import brandImg from "../../assets/brand-1.svg";
import logo from "../../assets/logo.png";
import fbIconBrown from "../../assets/fb-icon--brown.svg";
import igIconBrown from "../../assets/ig-icon--brown.svg";
import phoneIconBrown from "../../assets/phone-icon--brown.svg";
import locationIcon from "../../assets/location-icon--white.svg";

export default function Footer() {
  return (
    <footer>
      <div className="footer__brands">
        <Container>
          <Row>
            <Col xs="6" lg="3" className="text-center">
              <img src={brandImg} alt="Norteño Boots" className="img-fluid" />
            </Col>
            <Col xs="6" lg="3" className="text-center">
              <img src={brandImg} alt="Norteño Boots" className="img-fluid" />
            </Col>
            <Col xs="6" lg="3" className="text-center mt-5 mt-lg-0">
              <img src={brandImg} alt="Norteño Boots" className="img-fluid" />
            </Col>
            <Col xs="6" lg="3" className="text-center mt-5 mt-lg-0">
              <img src={brandImg} alt="Norteño Boots" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer__perks">
        <Container>
          <Row>
            <Col xs="6" lg="3" className="footer__perk">
              <h5>Sed ut perspiciatis unde</h5>
              <p> Totam rem aperiam.</p>
            </Col>
            <Col xs="6" lg="3" className="footer__perk">
              <h5>Sed ut perspiciatis unde</h5>
              <p> Totam rem aperiam.</p>
            </Col>
            <Col xs="6" lg="3" className="footer__perk mt-4 mt-lg-0">
              <h5>Sed ut perspiciatis unde</h5>
              <p> Totam rem aperiam.</p>
            </Col>
            <Col xs="6" lg="3" className="footer__perk mt-4 mt-lg-0">
              <h5>Sed ut perspiciatis unde</h5>
              <p> Totam rem aperiam.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer__content">
        <Container fluid>
          <Row>
            <Col lg="4">
              <img src={logo} alt="Norteño Boots" className="footer-brand" />
              <a
                href="https://www.google.com/maps/search/norte%C3%B1o+boots/@21.8836555,-102.2998616,17z/data=!3m1!4b1"
                target="_blank"
                rel="noreferrer"
                className="btn-store"
              >
                encuentra una tienda cerca de ti{" "}
                <img src={locationIcon} alt="Norteño Boots" />
              </a>
              <div className="footer__content__social">
                <div>
                  <a
                    href="https://www.facebook.com/Norte%C3%B1o-Boots-1427351847521118/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fbIconBrown} alt="Norteño Boots" />
                    Facebook
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/nortenobootsoficial/?hl=es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={igIconBrown} alt="Norteño Boots" />
                    Instagram
                  </a>
                </div>
                <div>
                  <a href="tel:4491234567">
                    <img src={phoneIconBrown} alt="Norteño Boots" />
                    (449) 123 45 67
                  </a>
                </div>
              </div>
            </Col>
            <Col xs="6" lg="2" className="mt-2 mt-lg-0">
              <h5>Norteño Boots</h5>
              <ul>
                <li>
                  <Link to="/#about">Nosotros</Link>
                </li>
                <li>
                  <Link to="/contact">Contacto</Link>
                </li>
              </ul>
            </Col>
            <Col xs="6" lg="2" className="mt-2 mt-lg-0">
              <h5>
                <Link to="/c/caballero">Caballero</Link>
              </h5>
              {/* <ul>
                <li>
                  <a href="">Botas</a>
                </li>
                <li>
                  <a href="">Botín</a>
                </li>
              </ul> */}
            </Col>
            <Col xs="6" lg="2" className="mt-2 mt-lg-0">
              <h5>
                <Link to="/c/dama">Dama</Link>
              </h5>
              {/* <ul>
                <li>
                  <a href="">Bota</a>
                </li>
                <li>
                  <a href="">Botín</a>
                </li>
              </ul> */}
            </Col>
            <Col xs="6" lg="2" className="mt-2 mt-lg-0">
              <h5>Información</h5>
              <ul>
                <li>
                  <a href="#">Aviso de privacidad</a>
                </li>
                <li>
                  <a href="#">Políticas de servicio</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer__bottom">
        <Container>
          <p>Todos los derechos reservados. 2022 Norteño Boots</p>
        </Container>
      </div>
    </footer>
  );
}
