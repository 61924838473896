import React, { useEffect } from "react";

import "./productCarousel.styles.scss";

import RightArrowIconUrl from "../../assets/right-arrow--light-black.svg";
// import LeftArrowIconUrl from "../../images/left-arrow--black.svg";

export const ProductCarousel = ({ productName, productImages }) => {
  const initCarousel = () => {
    // Product images carousel
    const slideContainer = document.querySelector(
      ".product-carousel__slide-container"
    );
    const slides = Array.from(slideContainer.children);
    const nextButton = document.querySelector(
      ".product-carousel__carousel__button--right"
    );
    const prevButton = document.querySelector(
      ".product-carousel__carousel__button--left"
    );
    const carouselNav = document.querySelector(
      ".product-carousel__carousel__nav"
    );
    const carouselPages = Array.from(carouselNav.children);

    const slideWidth = slides[0].getBoundingClientRect().width;

    slides[0]?.classList.add("current-slide");
    carouselPages[0]?.classList.add("current-page");

    // Slides layout
    const setSlideLayout = (slide, index) => {
      slide.style.left = slideWidth * index + "px";
    };

    slides.forEach(setSlideLayout);

    const slideMovement = (slideContainer, currentSlide, wantedSlide) => {
      slideContainer.style.transform =
        "translateX(-" + wantedSlide.style.left + ")";

      // Update current slide class
      currentSlide.classList.remove("current-slide");
      wantedSlide.classList.add("current-slide");
    };

    const updatePage = (currentPage, wantedPage) => {
      currentPage.classList.remove("current-page");
      wantedPage.classList.add("current-page");
    };
    if (slides.length === 1) {
      nextButton.classList.add("v-hidden");
      carouselNav.classList.add("v-hidden");
    }
    const hideShowButtons = (slides, prevButton, nextButton, wantedIndex) => {
      // console.log(slides);
      if (wantedIndex === 0) {
        prevButton.classList.add("v-hidden");
        nextButton.classList.remove("v-hidden");
      } else if (wantedIndex === slides.length - 1) {
        prevButton.classList.remove("v-hidden");
        nextButton.classList.add("v-hidden");
      } else {
        prevButton.classList.remove("v-hidden");
        nextButton.classList.remove("v-hidden");
      }
    };

    // Left movement on left click
    prevButton.addEventListener("click", (event) => {
      const currentSlide = slideContainer.querySelector(".current-slide");
      const prevSlide = currentSlide.previousElementSibling;

      const currentPage = carouselNav.querySelector(".current-page");
      const prevPage = currentPage.previousElementSibling;
      const prevIndex = carouselPages.findIndex((page) => page === prevPage);

      slideMovement(slideContainer, currentSlide, prevSlide);
      updatePage(currentPage, prevPage);
      hideShowButtons(slides, prevButton, nextButton, prevIndex);
    });

    // Right movement on right click
    nextButton.addEventListener("click", (event) => {
      const currentSlide = slideContainer.querySelector(".current-slide");
      const nextSlide = currentSlide.nextElementSibling;

      const currentPage = carouselNav.querySelector(".current-page");
      const nextPage = currentPage.nextElementSibling;
      const nextIndex = carouselPages.findIndex((page) => page === nextPage);

      slideMovement(slideContainer, currentSlide, nextSlide);
      updatePage(currentPage, nextPage);
      hideShowButtons(slides, prevButton, nextButton, nextIndex);
    });

    carouselNav.addEventListener("click", (event) => {
      // Get clicked page
      const wantedPage = event.target.closest("button");
      if (!wantedPage) return;

      const currentPage = carouselNav.querySelector(".current-page");
      const currentSlide = slideContainer.querySelector(".current-slide");
      const wantedIndex = carouselPages.findIndex(
        (page) => page === wantedPage
      );
      const wantedSlide = slides[wantedIndex];
      // console.log(wantedSlide);
      slideMovement(slideContainer, currentSlide, wantedSlide);
      updatePage(currentPage, wantedPage);
      hideShowButtons(slides, prevButton, nextButton, wantedIndex);
    });
  };

  useEffect(() => {
    initCarousel();
  }, []);
  return (
    <section className="product-carousel">
      <div className="product-carousel__container">
        <div className="product-carousel__carousel">
          <button className="product-carousel__carousel__button product-carousel__carousel__button--left v-hidden">
            <img
              className="product-carousel__carousel__button__icon fal fa-arrow-right"
              src={RightArrowIconUrl}
              alt="Portal Productos"
            />
          </button>
          <div className="product-carousel__slide-container">
            {productImages.map((productImage, index) => {
              // console.log(productImage);
              return (
                <div key={index} className="product-carousel__slide">
                  <img
                    // src={`${BASE_PATH}${productImage.attributes.url}`}
                    src={`${productImage.attributes.url}`}
                    // src={`${process.env.REACT_APP_API_URL}${productImage.attributes.url}`}
                    alt={productName}
                    className="product-carousel__slide__image"
                  />
                </div>
              );
            })}
          </div>
          <button className="product-carousel__carousel__button product-carousel__carousel__button--right">
            <img
              className="product-carousel__carousel__button__icon fal fa-arrow-right"
              src={RightArrowIconUrl}
              alt="Portal Productos"
            />
          </button>
        </div>
        <div className="product-carousel__carousel__nav">
          {productImages.map((productImage, index) => (
            <button
              key={index}
              className="product-carousel__carousel__pageButton"
            ></button>
          ))}
        </div>
      </div>
    </section>
  );
};
