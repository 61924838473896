/*
  // @route    GET /api/productos
  // @desc     Get all products
  // @access   Public
*/
export async function getProducts() {
  try {
    // TO-DO: get client's approval for sorting for catalogue
    const sortItem = "_sort=updatedAt:desc";
    const url = `${process.env.REACT_APP_API_URL}/api/productos?${sortItem}&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos
  // @desc     Get all products marked as new
  // @access   Public
*/
export async function getNewProducts() {
  try {
    // TO-DO: get client's approval for sorting for catalogue
    const sortItem = "sort=updatedAt:desc";
    const url = `${process.env.REACT_APP_API_URL}/api/productos?filters[nuevo][$eq]=true&${sortItem}&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[categoria][slug][$eq]=CATEGORYNAME
  // @desc     Get all products from a specific category
  // @access   Public
*/
export async function getCategoryProducts(categoryName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${process.env.REACT_APP_API_URL}/api/productos?[populate]=*&filters[categoria][slug][$eq]=${categoryName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos?[populate]=*&filters[categoria][slug][$eq]=CATEGORYNAME&filters[subcategorias][nombre][$eq]=SUBCATEGORYNAME
  // @desc     Get all products from a specific category and subcategory
  // @access   Public
*/
export async function getSubCategoryProducts(categoryName, subCategoryName) {
  try {
    // const sortItem = "_sort=updatedAt:desc";
    const url = `${process.env.REACT_APP_API_URL}/api/productos?[populate]=*&filters[categoria][slug][$eq]=${categoryName}&filters[subcategorias][slug][$eq]=${subCategoryName}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}

/*
  // @route    GET /api/productos/:id
  // @desc     Get a product
  // @access   Public
*/
export async function getProduct(id) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/productos/${id}?[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/productos-populares
  // @desc     Get popular products
  // @access   Public
*/

export async function getPopularProducts() {
  try {
    // TO-DO: get client's approval for sorting for catalogue
    const sortItem = "_sort=updatedAt:asc";
    const url = `${process.env.REACT_APP_API_URL}/api/productos-populares?${sortItem}&populate[producto][populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error");
    return null;
  }
}
