import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Context
import { useCartData } from "../../contexts/CartContext";

// API
import { getCategories } from "../../api/category";

// Bootstrap components
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Styles
import "./customNav.styles.scss";

// Assets
import fbIcon from "../../assets/fb-icon--white.svg";
import igIcon from "../../assets/ig-icon--white.svg";
import phoneYellowImage from "../../assets/phone--yellow-bg.svg";
import cartIcon from "../../assets/cart-icon--black.svg";
import logo from "../../assets/logo.png";

export default function CustomNav() {
  const data = useCartData();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      // setLoading(true);
      const response = await getCategories();
      if (response?.data.length > 0) {
        for (let index = 0; index < response?.data.length; index++) {
          delete response.data[index].attributes.createdAt;
          delete response.data[index].attributes.updatedAt;
          delete response.data[index].attributes.publishedAt;
          delete response.data[index].attributes.imagen;
          delete response.data[index].attributes.subcategorias.data[index]
            ?.attributes.createdAt;
          delete response.data[index].attributes.subcategorias.data[index]
            ?.attributes.updatedAt;
          delete response.data[index].attributes.subcategorias.data[index]
            ?.attributes.publishedAt;
        }
        setCategories(response.data);
        // setLoading(false);
      } else getCategories([]);
    })();
  }, []);

  // console.log(categories);

  return (
    <>
      <div className="navbar__top">
        <Container>
          <a
            href="https://www.facebook.com/Norte%C3%B1o-Boots-1427351847521118/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={fbIcon} alt="Norteño Boots" />
          </a>
          <a
            href="https://www.instagram.com/nortenobootsoficial/?hl=es"
            target="_blank"
            rel="noreferrer"
          >
            <img src={igIcon} alt="Norteño Boots" />
          </a>
        </Container>
      </div>
      <Navbar id="nav" collapseOnSelect expand="lg" variant="dark">
        <Container className="d-none d-lg-flex py-lg-2 ">
          <Row>
            <Col className="d-flex flex-row align-items-center">
              <img src={phoneYellowImage} alt="Norteño Boots" />
              <div className="d-flex flex-column justify-content-center">
                <span>dudas:</span>
                <a href="tel:4491234567">449 123 45 67</a>
              </div>
            </Col>
            <Col className="text-center">
              <Link to="/">
                <img src={logo} className="navbar-brand" alt="Norteño Boots" />
              </Link>
            </Col>
            <Col className="navbar-cart__container">
              <Link to="/bolsa" className="navbar-cart">
                <div className="navbar-cart__icon">
                  {/* <img src={cartIcon} alt="Norteño Boots" /> */}
                  <div className="icon icon-cart"></div>
                  <span>{data.itemCount}</span>
                </div>
                {/* <p>{formatMoney(data.total)}</p> */}
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="navbar-content">
          <Container>
            <Navbar.Brand className="d-lg-none">
              <Link to="/">
                <img
                  src={logo}
                  // className="d-block d-lg-none"
                  alt="Norteño Boots"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link as={Link} to="/" href="#features">
                  Inicio
                </Nav.Link>
                {categories.map((category, key) =>
                  category.attributes.subcategorias.data.length > 0 ? (
                    <NavDropdown
                      title={`${category.attributes.nombre}`}
                      id="basic-nav-dropdown"
                      key={key}
                    >
                      <h5>Categorías</h5>
                      {category.attributes.subcategorias.data?.map(
                        (subcategory, key) => (
                          <NavDropdown.Item
                            key={key}
                            as={Link}
                            to={`/c/${category.attributes.slug}/${subcategory.attributes.slug}`}
                            eventKey={key}
                          >
                            {subcategory.attributes.nombre}
                          </NavDropdown.Item>
                        )
                      )}
                      <NavDropdown.Item
                        as={Link}
                        to={`/c/${category.attributes.slug}`}
                        eventKey={category.attributes.subcategorias.data.length}
                      >
                        Ver todos
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <></>
                  )
                )}
                {/* <Nav.Link as={Link} to="/productos" eventKey={2}>
                  Caballero
                </Nav.Link>
                <Nav.Link href="#pricing" eventKey={2}>
                  Dama
                </Nav.Link> */}
                <Nav.Link href="#pricing" eventKey={2}>
                  Catálogos
                </Nav.Link>
                <Nav.Link href="/#about" eventKey={2}>
                  Acerca de
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" eventKey={2}>
                  Contacto
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <div className="navbar-cart__container d-lg-none">
              <Link to="/bolsa" className="navbar-cart">
                <div className="navbar-cart__icon">
                  <img src={cartIcon} alt="Norteño Boots" />
                  <span>{data.itemCount}</span>
                </div>
              </Link>
              {/* <p>$00.00</p> */}
            </div>
          </Container>
        </div>
      </Navbar>
      <div className="hero__alert">
        <h6>envío gratis</h6>
        <p>En todas las ordenes arriba de $1,200.00</p>
      </div>
    </>
  );
}
