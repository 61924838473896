/*
  // @route    GET /api/categorias/:id
  // @desc     Get a categoria
  // @access   Public
*/
export async function getCategory(id) {
  try {
    // const sortItem = "_sort=created_at:asc";
    const url = `${process.env.REACT_APP_API_URL}/api/categorias/${id}`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/categorias
  // @desc     Get all categorias
  // @access   Public
*/
export async function getCategories() {
  try {
    const sortItem = "_sort=nombre:asc";
    const url = `${process.env.REACT_APP_API_URL}/api/categorias?${sortItem}&[populate]=*`;
    const response = await fetch(url);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/*
  // @route    GET /api/productos
  // @desc     Get all products
  // @access   Public
*/
// export async function getProductsCategory(id) {
//   try {
//     const sortItem = "_sort=created_at:asc";
//     const url = `${process.env.REACT_APP_API_URL}/api/productos?_where[categoria_eq]=${id}&${sortItem}`;
//     const response = await fetch(url);
//     const result = await response.json();
//     return result;
//   } catch (error) {
//     console.log(error);
//     return null;
//   }
// }
