import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Context
import { CartProvider } from "./contexts/CartContext";

// Google Analytics
import ReactGA from "react-ga4";

// Custom components
import CustomNav from "./components/CustomNav/CustomNav";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

// Custom CSS
import "./scss/main.scss";

// Pages
import Home from "./pages/Home";
import Product from "./pages/Product";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Order from "./pages/Order";
import Category from "./pages/Category";
import Subcategory from "./pages/Subcategory";
import Contact from "./pages/Contact";

const TRACKING_ID = "G-TPJ52PM8YZ";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
  return (
    <CartProvider>
      <Router>
        <ScrollToTop />
        <header>
          <CustomNav />
        </header>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/c/:slug">
            <Category />
          </Route>
          <Route exact path="/c/:category/:slug">
            <Subcategory />
          </Route>
          {/* <Route exact path="/productos">
              <Catalogue />
            </Route> */}
          <Route exact path="/producto/:id">
            <Product />
          </Route>
          {/* <Route exact path="/productos/:name">
              <Category />
            </Route> */}
          <Route exact path="/bolsa">
            <Cart />
          </Route>
          <Route exact path="/pago">
            <Checkout />
          </Route>
          <Route exact path="/orden/:id">
            <Order />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;
