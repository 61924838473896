import React, { useContext, createContext, useState } from "react";

// uuid
import { v4 as uuidv4 } from "uuid";

const storage = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const setStorage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const sumItems = (cartItems) => {
  setStorage(cartItems);
  const itemCount = cartItems.reduce(
    (total, product) => total + product.quantity,
    0
  );
  const total = cartItems
    .reduce(
      (total, product) => total + product.attributes?.precio * product.quantity,
      0
    )
    .toFixed(2);
  return { itemCount, total };
};

const initialState = {
  cartItems: storage,
  ...sumItems(storage),
  checkout: false,
};

const CartContext = createContext();

export const useCartData = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const increase = (payload, quantity, productSize) => {
    // console.log(productSize);
    // console.log(
    //   state.cartItems.findIndex((item) => item.productSize === productSize)
    // );
    state.cartItems[
      state.cartItems.findIndex(
        (item) => item.id === payload.id && item.productSize === productSize
      )
    ].quantity += quantity;

    setState({
      ...state,
      ...sumItems(state.cartItems),
      cartItems: [...state.cartItems],
    });
    // dispatch({ type: 'INCREASE', payload });
  };

  const decrease = (payload) => {
    state.cartItems[state.cartItems.findIndex((item) => item.id === payload.id)]
      .quantity--;

    setState({
      ...state,
      ...sumItems(state.cartItems),
      cartItems: [...state.cartItems],
    });
    // dispatch({ type: 'DECREASE', payload });
  };

  // const addProduct2 = (payload, quantity, size) => {
  //   // !state.cartItems.find((item) => {
  //   //   item.id === payload.id && item.size === payload.size
  //   //     ? state.cartItems.push({
  //   //         ...payload,
  //   //       })
  //   //     : state.cartItems.push({
  //   //         ...payload,
  //   //         quantity: quantity,
  //   //         productSize: size,
  //   //       });
  //   // });
  //   console.log(size);
  //   if (!state.cartItems.find((item) => item.productSize === size)) {
  //     payload.quantity
  //       ? state.cartItems.push({
  //           ...payload,
  //         })
  //       : state.cartItems.push({
  //           ...payload,
  //           quantity: quantity,
  //           productSize: size,
  //         });
  //   }

  //   setState({
  //     ...state,
  //     ...sumItems(state.cartItems),
  //     cartItems: [...state.cartItems],
  //   });
  //   // dispatch({ type: 'ADD_ITEM', payload });
  // };
  const addProduct = (payload, quantity, productSize, productSizeId) => {
    const skuId = uuidv4().slice(0, 7);
    // console.log(productSizeId);
    if (
      !state.cartItems.find(
        (item) => item.id === payload.id && item.productSize === productSize
      )
    ) {
      payload.quantity
        ? state.cartItems.push({
            ...payload,
          })
        : state.cartItems.push({
            ...payload,
            skuId,
            quantity,
            productSize,
            productSizeId,
          });
    }

    setState({
      ...state,
      ...sumItems(state.cartItems),
      cartItems: [...state.cartItems],
    });
    // dispatch({ type: 'ADD_ITEM', payload });
  };
  const removeProduct = (payload) => {
    setState({
      ...state,
      ...sumItems(
        state.cartItems.filter((item) => item.skuId !== payload.skuId)
      ),
      cartItems: [
        ...state.cartItems.filter((item) => item.skuId !== payload.skuId),
      ],
    });
    // dispatch({ type: 'REMOVE_ITEM', payload });
  };

  const handleCheckout = () => {
    setState({
      cartItems: [],
      checkout: true,
      ...sumItems([]),
    });
    // dispatch({ type: 'CHECKOUT' });
  };

  const contextValues = {
    removeProduct,
    addProduct,
    increase,
    decrease,
    handleCheckout,
    ...state,
  };

  return (
    <CartContext.Provider value={contextValues}>
      {children}
    </CartContext.Provider>
  );
};
