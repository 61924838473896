import React from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import Col from "react-bootstrap/Col";

// Custom Styles
import "./productCard.styles.scss";

// Utils
import formatMoney from "../../utils/formatMoney";

export default function ProductCard({
  id,
  product,
  media,
  name,
  category,
  sizes,
  price,
}) {
  // console.log(product);
  return (
    <Col xs="6" lg="3">
      <div className="product-card">
        {product?.attributes.nuevo ? (
          <span className="product-card__new">Nuevo</span>
        ) : (
          <></>
        )}
        <Link to={`/producto/${id}`}>
          <div className="product-card__media">
            <img
              src={`${product?.attributes?.imagenes.data[0].attributes.url}`}
              // src={`${process.env.REACT_APP_API_URL}${product.attributes?.imagenes.data[0].attributes.url}`}
              className=""
              alt="Norteño Boots"
            />
          </div>
        </Link>
        <div className="product-card__body">
          <span>{product.attributes?.categoria.data.attributes?.nombre}</span>
          <Link to={`/producto/${product.id}`}>
            <h3>{product.attributes?.nombre}</h3>
          </Link>
          <div className="sizes">
            <span>tallas:</span>
            {product.attributes?.tallas.map((talla, key) => (
              <p key={key}>{talla.talla} </p>
            ))}
          </div>
          <p className="product-card__body__price">
            {formatMoney(product.attributes?.precio)}
          </p>
        </div>
      </div>
    </Col>
  );
}
