import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// API
import { getPopularProducts, getNewProducts } from "../api/product";
import { getSubcategories } from "../api/subcategory";

// Styles
import "../scss/pages/home.styles.scss";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom components
import ProductCard from "../components/ProductCard/ProductCard";

// Assets
import truckIcon from "../assets/shipping-icon--red.svg";
import secureIcon from "../assets/secure-icon--red.svg";
import returnIcon from "../assets/return-icon--red.svg";

import product1 from "../assets/product-1.jpg";
import product2 from "../assets/product-2.jpg";

import aboutUs1 from "../assets/section-img--1.jpg";
import aboutUs2 from "../assets/section-img--2.jpg";

// import category1 from "../assets/category-1.jpg";
// import category2 from "../assets/category-2.jpg";
import genre1 from "../assets/lady.jpg";
import genre2 from "../assets/gentlemen.jpg";
import productGrid1 from "../assets/product-1-grid.png";
import fbIconWhite from "../assets/fb-icon--white.svg";
import igIconWhite from "../assets/ig-icon--white.svg";

export default function Home() {
  const [popularProducts, setPopularProducts] = useState(null);
  const [newProducts, setNewProducts] = useState(null);
  const [subcategories, setSubcategories] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getPopularProducts();
      if (response?.data.length > 0) {
        setPopularProducts(response.data);
        setLoading(false);
      } else setPopularProducts([]);
    })();
  }, []);
  // console.log(popularProducts);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getNewProducts();
      if (response?.data.length > 0) {
        setNewProducts(response.data);
        setLoading(false);
      } else setNewProducts([]);
    })();
  }, []);
  // console.log(newProducts);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getSubcategories();
      if (response?.data.length > 0) {
        setSubcategories(response.data);
        setLoading(false);
      } else setSubcategories([]);
    })();
  }, []);
  // console.log(subcategories);

  return (
    <>
      <section className="hero">
        <div>
          <h1>Sed ut perspiciatis unde omnis iste natus error sit.</h1>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam.
          </p>
          <a href="#newProducts">Comprar</a>
        </div>
      </section>
      <section className="home-perks">
        <Container>
          <Row>
            <Col xs="12" lg="4">
              <div className="home-perks__perk">
                <img src={truckIcon} alt="Norteño Boots" />
                <div>
                  <h3>Free Shipping</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit, velit.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs="12" lg="4">
              <div className="home-perks__perk">
                <img src={secureIcon} alt="Norteño Boots" />
                <div>
                  <h3>Free Shipping</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit, velit.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs="12" lg="4">
              <div className="home-perks__perk">
                <img src={returnIcon} alt="Norteño Boots" />
                <div>
                  <h3>Free Shipping</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit, velit.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="newProducts" className="new-products">
        <Container>
          <h2>Productos Nuevos</h2>
          <p>Productos de nueva temporada</p>
          <div className="new-products__cards__wrapper">
            <div className="new-products__cards__container">
              <Row>
                {newProducts?.map((product, key) => (
                  <ProductCard
                    key={key}
                    id={product.id}
                    product={product}
                    // media={product.media}
                    // name={product.name}
                    // category={product.category}
                    // sizes={product.sizes}
                    // price={product.price}
                  />
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </section>
      <section id="about" className="about-us">
        <Container fluid>
          <h5>
            Norteño Boots sed ut perspiciatis unde omnis iste natus error sit
            voluptatem accusantium doloremque laudantium, totam rem aperiam.
          </h5>
          <Row className="about-us__media">
            <Col lg="3" className="text-center text-lg-end order-3 order-lg-1">
              <h6>Otoño/Invierno 2021</h6>
              <p>
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam.
              </p>
              <Link to="/productos">Comprar</Link>
            </Col>
            <Col
              lg="5"
              xxl="4"
              className="text-center background order-2 order-lg-2"
            >
              <img src={aboutUs1} alt="Norteño Boots" className="img-fluid " />
            </Col>
            <Col lg="4" xxl="5" className="text-center order-1 order-lg-3">
              <img src={aboutUs2} alt="Norteño Boots" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="categories">
        <Container>
          <h2>Compra por subcategoría</h2>
          <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</p>
          <div className="categories__cards__wrapper">
            <div className="categories__cards__container">
              {subcategories?.map((subcategory, key) => (
                <Link to={`/c/${subcategory.attributes.slug}`}>
                  <div className="category-card" key={key}>
                    <div className="category-card__media">
                      <img
                        src={`${process.env.REACT_APP_API_URL}${subcategory.attributes.imagen.data.attributes.url}`}
                        className="img-fluid"
                        alt="Norteño Boots"
                      />
                    </div>
                    <div className="category-card__body">
                      <p>{subcategory.attributes.nombre}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Container>
      </section> */}
      <section className="genres">
        <Container fluid>
          <Row>
            <Col lg="6">
              <img src={genre1} alt="Norteño Boots" className="img-fluid" />
              <Link to="/c/dama">Dama</Link>
            </Col>
            <Col lg="6" className="mt-2 mt-lg-0">
              <img src={genre2} alt="Norteño Boots" className="img-fluid" />
              <Link to="/c/caballero">Caballero</Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="featured-products">
        <Container>
          <h2>Productos Destacados/Temporada</h2>
          <p>Productos de nueva temporada</p>
          <div className="featured-products__cards__wrapper">
            <div className="featured-products__cards__container">
              {popularProducts?.map((product, key) => {
                console.log(product);
                return (
                  <ProductCard
                    key={key}
                    id={product.id}
                    product={product.attributes.producto.data}
                  />
                );
              })}
            </div>
          </div>
        </Container>
      </section>
      <section className="grid">
        <Container>
          <div className="grid-container">
            <div className="category">
              <div>
                <div>
                  <span>otoño/invierno 2021</span>
                  <h5>Botas</h5>
                  <a href="">comprar</a>
                </div>
                <div>
                  <img src={productGrid1} alt="Norteño Boots" />
                </div>
              </div>
            </div>
            <div className="social">
              <div>
                <h4>síguenos</h4>
                <div className="social__links">
                  <a
                    href="https://www.facebook.com/Norte%C3%B1o-Boots-1427351847521118/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={fbIconWhite} alt="Norteño Boots" /> Facebook
                  </a>
                  <a
                    href="https://www.instagram.com/nortenobootsoficial/?hl=es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={igIconWhite} alt="Norteño Boots" /> Instagram
                  </a>
                </div>
              </div>
            </div>
            <div className="media"></div>
          </div>
        </Container>
      </section>
    </>
  );
}
