import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Context
import { useCartData } from "../../contexts/CartContext";

// Stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

// API
import { createOrder } from "../../api/order";

// Utils
import formatMoney from "../../utils/formatMoney";

// Custom components
import CardSection from "../CardSection/CardSection";

// Bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

// Custom styles
import "./paymentForm.styles.scss";

// Axios for sending email
import axios from "axios";

// Formik for form validation
import { Formik } from "formik";
import * as yup from "yup";

// uuid
import { v4 as uuidv4 } from "uuid";

// Assets
// import creditCardIcon from "../../images/credit-card--black.svg";

const initialValues = {
  clientName: "",
  // lastName: "",
  clientEmail: "",
  phoneNumber: "",
  address: "",
  streetNumber: "",
  neighborhood: "",
  reference: "",
  postalCode: 0,
  municipality: "",
  city: "",
  state: "",
};
const phoneRegExp = /^[0-9]{10}$/;

let schema = yup.object().shape({
  clientName: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  clientEmail: yup
    .string()
    .email("Ingresa un email válido.")
    .required("Este campo es requerido"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Por favor proporciona tu número a 10 dígitos")
    .required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  streetNumber: yup.string().required("Este campo es requerido"),
  neighborhood: yup.string().required("Este campo es requerido"),
  reference: yup.string().required("Este campo es requerido"),
  // lastName: yup.string().required("Este campo es requerido"),
  postalCode: yup
    .number()
    .required("Este campo es requerido")
    .moreThan(10000, "Ingresa un Código Postal válido"),
  municipality: yup.string().required("Este campo es requerido"),
  city: yup.string().required("Este campo es requerido"),
  state: yup.string().required("Este campo es requerido"),
});

export default function PaymentForm() {
  const { total, cartItems, handleCheckout } = useCartData();
  const [error, setError] = useState();
  const [shipping, setShipping] = useState(25);
  const [loading, setLoading] = useState(false);
  const orderTotal = parseFloat(total) + shipping;
  const history = useHistory();
  // console.log(cartItems);

  const stripe = useStripe();
  const elements = useElements();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        // e.preventDefault();
        setLoading(true);
        // console.log(loading);
        const today = new Date();
        const date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();
        const orderId = uuidv4().slice(0, 7);
        for (let index = 0; index < cartItems.length; index++) {
          // delete cartItems[index].attributes.imagenes;
          delete cartItems[index].attributes.descripcion;
          delete cartItems[index].attributes.descripcion2;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            email: values.clientEmail,
            name: values.clientName,
            phone: values.clientPhoneEmail,
            address: {
              city: values.city,
              country: "MX",
              line1: values.address,
              line2: values.neighborhood,
              state: values.state,
            },
          },
        });
        console.log(error);
        try {
          // if (!error) {
          // }

          const response = await createOrder({
            data: {
              token: paymentMethod.id,
              // token: "44hdgegeggggg",
              orderId,
              clientName: values.clientName,
              clientEmail: values.clientEmail,
              phoneNumber: values.phoneNumber,
              address: values.address,
              streetNumber: values.streetNumber,
              neighborhood: values.neighborhood,
              postalCode: parseInt(values.postalCode),
              reference: values.reference,
              municipality: values.municipality,
              city: values.city,
              state: values.state,
              country: "México",
              total: orderTotal,
              orderItems: cartItems,
              shipping,
            },
          });
          // console.log(response);
          if (response.status === 500) {
            setLoading(false);
            setError(
              "Tu compra fue realizada pero tuvimos problemas en guardar tu orden de compra. Error 500. Contáctanos para poder asistirte."
            );

            setTimeout(() => {
              setError("");
              // history.push(`/`);
              // handleCheckout();
            }, 5000);
          } else if (response.status === 410) {
            // console.log(response);
            setLoading(false);
            response.json().then((json) => {
              // console.log(json);
              // const pr = json.filter((item) => item !== null);
              // console.log(pr);
              setError(
                `Desafortunadamente el producto ${json.product.nombre} en talla ${json.intersection[0].talla} está fuera de stock.`
                // "Hola"
              );
              // if (pr.length >= 1) {
              // }
              // if (json.find((item) => item !== null)) {
              //   // if (json[0] === null) {

              // }
            });
            setTimeout(() => {
              setError("");
              // history.push(`/`);
              // handleCheckout();
            }, 7000);
          } else if (response.status === 406) {
            // console.log(response);
            setLoading(false);
            response.json().then((json) => {
              setError(
                `${json.product} talla ${json.stock} no tiene el stock suficiente para completar tu orden. Elimina el producto y consulta el stock disponible.`
              );
            });
            setTimeout(() => {
              setError("");
            }, 8000);
            return;
          } else if (response.status === 409) {
            // Stripe error
            // console.log(response);
            setLoading(false);
            response.json().then((json) => {
              setError(json.error);
            });
            setTimeout(() => {
              setError("");
            }, 8000);
            return;
          } else if (response.ok) {
            axios
              .all([
                axios.post(`${process.env.REACT_APP_API_URL}/api/email`, {
                  to: ["rodrigo.zenteno7@gmail.com"],
                  subject: "Nueva orden de compra",
                  html: `
                  <html lang="es">

                  <head>
                    <meta charset="UTF-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400&family=Outfit:wght@400;600&display=swap"
                      rel="stylesheet">
                  </head>

                  <body style="margin: 0; padding: 0;">
                    <div style="font-family: 'Jost', sans-serif; background-color: #252D30; padding: 2rem 1rem; height: auto;">
                      <div
                        style="width: 90vw; height: auto; margin: auto;  background-color: #fff; border-bottom: 10px solid #F6B95D; padding: 0; overflow: hidden;">
                        <div style="padding: 2rem;">
                          <h2
                            style="text-transform: uppercase; font-family: 'Outfit', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000;">
                            Nueva orden de compra de e-commerce Norteño Boots</h2>
                          <p style="margin-bottom: 1rem; color: #000;">${
                            values.clientName
                          } envío una orden de compra.</p>
                          <h3 style="font-family: 'Outfit', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000;">
                            Datos de contacto:</h3>
                          <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Fecha:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${date}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Nombre:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientName}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Email:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientEmail}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Teléfono:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.phoneNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Dirección:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.address}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Colonia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.neighborhood}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Referencia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.reference}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Municipio:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.municipality}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Ciudad:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.city}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Estado:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.state}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Código Postal:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.postalCode}</td>
                            </tr>
                          </table>
                          <h3 style="font-family: 'Outfit', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000">
                            Orden de compra: ${orderId}</h3>
                          <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0; ">
                            ${cartItems.map(
                              (item) => `
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Producto:</strong>
                              </td>
                              <td valign="top"
                                style="width:100%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${item.attributes.nombre}
                              </td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Cantidad:</strong>
                              </td>
                              <td valign="top"
                                style="width:100%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${item.quantity}
                              </td>
                            </tr>
                            `
                            )}
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Total:</strong>
                              </td>
                              <td valign="top"
                                style="width:100%; border-top:1px solid #dfdfdf; font-size:13px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${formatMoney(orderTotal)} MNX
                              </td>
                            </tr>
                          </table>
                        </div>
                        <img src="https://nortenoboots.com.mx/static/media/logo.06179ea1a57ea2df1779e9f76ba01d94.svg" alt="Norteño Boots" title="Norteño Boots" style="display: block; margin: 1rem auto;"
                          width="205px" height="auto" />
                      </div>
                    </div>
                  </body>

                  </html>`,
                }),
                axios.post(`${process.env.REACT_APP_API_URL}/api/email`, {
                  to: [`${values.clientEmail}`],
                  subject: "Confirmación de orden",
                  html: `
                  <html lang="es">

                  <head>
                    <meta charset="UTF-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400&family=Outfit:wght@400;600&display=swap"
                      rel="stylesheet">
                  </head>

                  <body style="margin: 0; padding: 0; ">
                    <div style="font-family: 'Jost', sans-serif; background-color: #252D30; padding: 2rem 1rem; height: auto;">
                      <div style="width: 90vw; height: auto; margin: auto;  background-color: #fff; padding:0; overflow: hidden;">
                        <div class="heading" style="background-color: #F5F5F6; padding: 2rem; border-bottom: 7px solid #7E4225;">
                          <img src="https://nortenoboots.com.mx/static/media/logo.06179ea1a57ea2df1779e9f76ba01d94.svg" style="height: 60px;" alt="Norteño Boots">
                        </div>
                        <div style="padding: 1rem 1.5rem;">
                          <h2
                            style="text-transform: uppercase; font-family: 'Outfit', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000; text-align: center;">
                            Confirmación de orden</h2>
                          <div style="text-align: center;">
                            <img src="https://nortenoboots.com.mx/static/media/check-icon--green.2ca13060682abd2834052cf2896fb941.svg" alt="Norteño Boots" title="Norteño Boots"
                              style="display: block; margin: 1rem auto;" width="80px" height="auto" />
                            <h3 style="margin-bottom: .5rem;">¡Gracias por tu compra!</h3>
                            <p style="margin-bottom: 1rem; margin-top: 0; color: #000;">Número de orden #${orderId}</p>
                          </div>
                          <h3 style="font-family: 'Outfit', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000;">
                            Detalles de envío</h3>
                          <table width="100%" cellpadding="0" cellspacing="0" style="margin-bottom:1rem; border: 0;">
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Fecha:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${date}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Nombre:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientName}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Email:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.clientEmail}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Teléfono:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.phoneNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Dirección:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.address}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Número:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.streetNumber}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Colonia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.neighborhood}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Referencia:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.reference}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Municipio:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.municipality}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Ciudad:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.city}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Estado:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.state}</td>
                            </tr>
                            <tr>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 5px 7px 0;">
                                <strong>Código Postal:</strong>
                              </td>
                              <td valign="top"
                                style="width:50%; border-top:1px solid #dfdfdf; font-size:15px; color:#000; padding:7px 0 7px 5px; text-align: right;">
                                ${values.postalCode}</td>
                            </tr>
                          </table>
                          <h3 style="font-family: 'Outfit', sans-serif; font-weight: bold; margin-bottom: 1rem; color: #000">
                            Detalles de la orden:</h3>
                          <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">

                            ${cartItems.map(
                              (item) => `
                            <div style="display: flex; justify-content: space-between;">
                              <div style="display: flex; margin-top: .8rem;">
                                <p style="margin: 0; margin-right: 1rem;">${
                                  item.quantity
                                } x</p>
                                <p style=" margin: 0; font-weight: bolder;">${
                                  item.attributes.nombre
                                }</p>
                              </div>
                              <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                item.attributes.precio
                              )} MNX
                              </p>
                            </div>
                            `
                            )}
                          </div>
                          <div style="border-bottom: 1px dashed #DCDCDC; padding-bottom: 1rem;">
                            <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                              <p style="margin: 0; font-size: 1.1rem;">Envío</p>
                              <p style="margin-bottom: 0; font-size: 18px; color: #74889D; margin-top: .8rem;">${formatMoney(
                                shipping
                              )} MNX</p>
                            </div>
                          </div>
                          <div style="display: flex; justify-content: space-between; padding: .7rem 0;">
                            <h5 style="margin: 0; font-size: 1.1rem;">Total</h5>
                            <p style="margin: 0; color: #547A3C; font-size: 1.4rem;">${formatMoney(
                              orderTotal
                            )} MNX</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </body>

                  </html>`,
                }),
              ])
              .then((res) => {
                // console.log(res);
                history.push(`/orden/${orderId}`);
                setTimeout(() => {
                  handleCheckout();
                  setLoading(false);
                }, 1000);
              })
              .catch(function (error) {
                console.log(error);
                setLoading(false);
              });
            // console.log("corrió");
            setLoading(false);
          } else {
            setLoading(false);
            console.log(response);
            setError(
              "Hubo un error en tu método de pago. Verifica que los datos sean correctos o comunícate con tu institución bancaria."
            );
            setTimeout(() => {
              setError("");
            }, 5000);
            return;
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          setError(
            "Hubo un error en tu método de pago. Verifica que los datos sean correctos o comunícate con tu institución bancaria."
          );
          setTimeout(() => {
            setError("");
          }, 5000);
          // setLoading(false);
          return;
        }
        // setLoading(false);
        // console.log(loading);
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        // }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <>
          <div className={`overlay ${loading && "show"}`}></div>
          <Form onSubmit={handleSubmit} className="payment-form">
            <div className="payment-form__contact-info">
              <h4>Información de contacto</h4>
              <Form.Group className="mb-3" controlId="formGridAddress1">
                <Form.Label>
                  Nombre completo: <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="clientName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.clientName}
                />
                <span
                  style={{
                    color: "#c93030",
                    fontWeight: "bolder",
                    marginTop: ".6rem",
                    padding: "0",
                    fontSize: ".9rem",
                  }}
                >
                  {errors.clientName && touched.clientName && errors.clientName}
                </span>
              </Form.Group>
              <Row>
                <Form.Group as={Col} lg="6" controlId="formGridAddress1">
                  <Form.Label>
                    Email: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="clientEmail"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clientEmail}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.clientEmail &&
                      touched.clientEmail &&
                      errors.clientEmail}
                  </span>
                </Form.Group>
                <Form.Group as={Col} lg="6" controlId="formGridAddress1">
                  <Form.Label>
                    Teléfono: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="tel"
                    name="phoneNumber"
                    // value={values.phoneNumber}
                    // onChange={handleInputChange}
                    // required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </span>
                </Form.Group>
              </Row>
            </div>
            <div className="payment-form__shipment-info">
              <h4>Información de envío</h4>
              <Row>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Calle: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.address && touched.address && errors.address}
                  </span>
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Número: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="streetNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.streetNumber}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.streetNumber &&
                      touched.streetNumber &&
                      errors.streetNumber}
                  </span>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Referencia: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="reference"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.reference}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.reference && touched.reference && errors.reference}
                  </span>
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Colonia: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="neighborhood"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.neighborhood}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.neighborhood &&
                      touched.neighborhood &&
                      errors.neighborhood}
                  </span>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Código Postal: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.postalCode &&
                      touched.postalCode &&
                      errors.postalCode}
                  </span>
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Municipio: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="municipality"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.municipality}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.municipality &&
                      touched.municipality &&
                      errors.municipality}
                  </span>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Ciudad: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.city && touched.city && errors.city}
                  </span>
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg="6"
                  className="mb-3"
                  controlId="formGridAddress1"
                >
                  <Form.Label>
                    Estado: <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    name="state"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  />
                  <span
                    style={{
                      color: "#c93030",
                      fontWeight: "bolder",
                      marginTop: ".6rem",
                      padding: "0",
                      fontSize: ".9rem",
                    }}
                  >
                    {errors.state && touched.state && errors.state}
                  </span>
                </Form.Group>
              </Row>
              <h4>Pago</h4>
              <CardSection />
              {error ? (
                <span
                  style={{
                    color: "#c93030",
                    fontWeight: "bolder",
                    marginBottom: ".6rem",
                    padding: "0",
                    fontSize: "1rem",
                  }}
                >
                  {error}
                </span>
              ) : undefined}
              <button
                type="submit"
                disabled={isSubmitting || cartItems.length === 0}
                className={loading ? "loading" : undefined}
              >
                {loading && (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                )}
                <span className="btn__text">
                  {loading
                    ? "Procesando compra"
                    : `Completar compra ${formatMoney(
                        parseFloat(total) + shipping
                      )}`}
                </span>
              </button>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
