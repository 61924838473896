import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Bootstrap components
import Container from "react-bootstrap/Container";

// Custom Styles
import "../scss/pages/order.styles.scss";

// Assets
import successIcon from "../assets/check-icon--green.svg";

export default function Order() {
  const { id } = useParams();
  // console.log(id);
  return (
    <main className="order">
      <Container className="order-container">
        <img src={successIcon} alt="Norteño Boots" />
        <h5>Orden creada exitosamente</h5>
        <p>Número de orden #{id}</p>
        <span>Revisa la bandeja de entrada de tu correo electrónico</span>
        <Link to="/">Regresar</Link>
      </Container>
    </main>
  );
}
