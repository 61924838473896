import React from "react";
import { Link } from "react-router-dom";

// Context
import { useCartData } from "../contexts/CartContext";

// Bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Styles
import "../scss/pages/cart.styles.scss";

// Assets
// import product2 from "../assets/product-2.jpg";
import trashIcon from "../assets/delete-icon.svg";

// Utils
import formatMoney from "../utils/formatMoney";

function CartItem({ cartItem, removeProduct }) {
  // console.log(cartItem);
  return (
    <div className="cart__list__item">
      <div className="cart__list__item__media">
        <img
          src={`${cartItem.attributes?.imagenes?.data[0].attributes.url}`}
          // src={`${process.env.REACT_APP_API_URL}${cartItem.attributes.imagenes?.data[0].attributes.url}`}
          alt="Norteño Boots"
        />
      </div>
      <div className="cart__list__item__details">
        <div className="cart__list__item__details__heading">
          <div>
            <h3>{cartItem.attributes?.nombre}</h3>
            <p>{cartItem.attributes?.skuId}</p>
          </div>
          <p className="cart__list__item__details__heading__price">
            {formatMoney(cartItem.attributes?.precio)}
          </p>
        </div>
        <div className="cart__list__item__details__body">
          <p>
            <span>talla: </span>
            {cartItem.productSize}
          </p>
          <p>
            <span>Cantidad: </span>
            {cartItem.quantity}
          </p>
          <button type="button" onClick={() => removeProduct(cartItem)}>
            <img src={trashIcon} alt="Norteño Boots" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default function Cart() {
  const { cartItems, itemCount, total, removeProduct } = useCartData();
  // console.log(cartItems);
  return (
    <>
      <Container className="cart__container">
        <Row>
          <Col lg="8">
            <div className="cart__list">
              {cartItems.length === 0 ? (
                <h6 className="text-center">Tu bolsa está vacía</h6>
              ) : (
                cartItems.map((cartItem, key) => (
                  <CartItem
                    key={key}
                    cartItem={cartItem}
                    removeProduct={removeProduct}
                  />
                ))
              )}
            </div>
          </Col>
          <Col lg="4">
            <div className="cart__summary">
              <h5>Resumen</h5>
              <div>
                <p>Número de piezas</p>
                <p>{itemCount}</p>
              </div>
              <div>
                <p>Total de productos</p>
                <p>{formatMoney(total)}</p>
              </div>
              <div>
                <p>Costo de envío</p>
                <p>$200.00</p>
              </div>
              <div className="cart__summary__total">
                <h6>Total</h6>
                <p>{formatMoney(total)}</p>
              </div>
              {cartItems.length === 0 ? (
                <></>
              ) : (
                <Link to="/pago">Continuar</Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
